import React, { useContext, useEffect, useState } from "react";
import { AUTHORIZED_USERS } from "../utils/users";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  function login(email, password) {
    const user = AUTHORIZED_USERS.find(
      (u) => u.email === email && u.password === password
    );
    
    if (user) {
      setCurrentUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      return Promise.resolve(user);
    }
    return Promise.reject(new Error("Invalid email or password"));
  }

  function logout() {
    setCurrentUser(null);
    localStorage.removeItem('user');
    return Promise.resolve();
  }

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setCurrentUser(JSON.parse(user));
    }
    setLoading(false);
  }, []);

  const value = {
    currentUser,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
} 