import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import { useAuth } from '../contexts/AuthContext';
import ProposalTemplate from '../ProposalTemplate';

function Dashboard() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDeal, setSelectedDeal] = useState(null);

  const PIPEDRIVE_API_TOKEN = 'efffe9efbc7107515abaa85377f114e2869d40af';
  const PIPEDRIVE_BASE_URL = 'https://tinikola-sandbox.pipedrive.com/v1/deals';

  useEffect(() => {
    const fetchAllDeals = async () => {
      try {
        let allDeals = [];
        let hasMore = true;
        let start = 0;
        const limit = 100;

        while (hasMore) {
          const response = await axios.get(
            `${PIPEDRIVE_BASE_URL}?api_token=${PIPEDRIVE_API_TOKEN}&limit=${limit}&start=${start}`
          );

          if (response.data && response.data.data) {
            allDeals = [...allDeals, ...response.data.data];
            hasMore = response.data.additional_data?.pagination?.more_items_in_collection || false;
            start += limit;
          } else {
            hasMore = false;
          }
        }

        setDeals(allDeals);
      } catch (error) {
        console.error("Error fetching deals:", error);
        setError('Failed to fetch deals');
      } finally {
        setLoading(false);
      }
    };

    fetchAllDeals();
  }, []);

  const handleSelectDeal = (e) => {
    const dealId = e.target.value;
    const deal = deals.find((d) => d.id === parseInt(dealId));
    setSelectedDeal(deal);
  };

  const generatePDF = () => {
    const input = document.getElementById("pdfContent");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.8);
      const pdf = new jsPDF("portrait", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
      pdf.save(`Propuesta_${selectedDeal.person_name}.pdf`);
    });
  };

  const filteredDeals = deals.filter((deal) =>
    deal.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      setError('Failed to log out');
    }
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img 
              src={logo} 
              alt="Logo" 
              style={{ 
                height: "40px",
                width: "auto",
                filter: "brightness(0) invert(1)"
              }} 
            />
          </a>
          
          <div className="d-flex align-items-center">
            <span className="text-white me-3">
              {currentUser.email}
            </span>
            <button 
              className="btn btn-outline-light"
              onClick={handleLogout}
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      </nav>

      <div className="container py-5">
        <div className="row mb-5">
          <div className="col-12">
            <div className="card shadow-sm">
              <div className="card-body text-center">
                <h1 className="display-4 mb-4">Generador de Propuestas</h1>
                <p className="lead text-muted">
                  Selecciona un trato de Pipedrive para generar una propuesta comercial
                </p>
              </div>
            </div>
          </div>
        </div>

        {error && <div className="alert alert-danger mt-3">{error}</div>}

        {loading ? (
          <div className="text-center py-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Cargando...</span>
            </div>
            <p className="mt-3">Cargando tratos...</p>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card shadow-sm">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="dealSearch" className="form-label fw-bold mb-3">
                      Buscar Trato:
                    </label>
                    <input
                      type="text"
                      id="dealSearch"
                      className="form-control form-control-lg mb-3"
                      placeholder="Buscar por nombre del trato..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <label htmlFor="dealDropdown" className="form-label fw-bold mb-3">
                      Seleccionar Trato:
                    </label>
                    <select 
                      id="dealDropdown" 
                      className="form-select form-select-lg mb-3" 
                      onChange={handleSelectDeal}
                      defaultValue=""
                    >
                      <option value="" disabled>-- Selecciona un trato --</option>
                      {filteredDeals.map((deal) => (
                        <option key={deal.id} value={deal.id}>
                          {deal.title} - {deal.currency} {deal.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedDeal && (
          <div className="row mt-5">
            <div className="col-12">
              <div className="card shadow">
                <div className="card-body pt-0">
                  <ProposalTemplate deal={selectedDeal} />
                  <div className="text-center mt-4">
                    <button 
                      onClick={generatePDF} 
                      className="btn btn-primary btn-lg px-5"
                    >
                      <i className="fas fa-file-pdf me-2"></i>
                      Generar PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard; 