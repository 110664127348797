export const AUTHORIZED_USERS = [
  {
    email: "ti@nikola.cl",
    password: "Nikola123",
  },
  {
    email: "j.obrecht@nikola.cl",
    password: "Nikola123",
  },
  {
    email: "b.anrique@nikola.cl",
    password: "Nikola123",
  },
  {
    email: "b.bittelman@nikola.cl",
    password: "Nikola123",
  },
  {
    email: "p.infante@nikola.cl",
    password: "Nikola123",
  },
  {
    email: "f.veram@nikola.cl",
    password: "Nikola123",
  },
  {
    email: "s.sanchez@nikola.cl",
    password: "Nikola123",
  },

  {
    email: "c.zapata@nikola.cl",
    password: "Nikola123",
  },
  // Add more users as needed
];
