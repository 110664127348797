// Constants
const COMUNA_KWH_ANNUAL = {
  "Antofagasta": 1730,
    "Chicureo":1679,
    "Chacabuco":1679,
    "Calama": 2072,
    "Mejillones": 1811,
    "Sierra Gorda": 2020,
    "Taltal": 1567,
    "Tocopilla": 1732,
    "Arica": 1545,
    "Camarones": 1826,
    "Putre": 1968,
    "Alto Del Carmen": 1891,
    "Caldera": 1536,
    "Chañaral": 1634,
    "Copiapó": 1814,
    "Diego De Almagro": 1946,
    "Freirina": 1687,
    "Huasco": 1415,
    "Tierra Amarilla": 1851,
    "Vallenar": 1791,
    "Andacollo": 1901,
    "Canela": 1618,
    "Combarbalá": 1862,
    "Coquimbo": 1374,
    "Illapel": 1724,
    "La Higuera": 1793,
    "La Serena": 1411,
    "Los Vilos": 1417,
    "Monte Patria": 1800,
    "Ovalle": 1650,
    "Paiguano": 1600,
    "Punitaqui": 1732,
    "Río Hurtado": 1833,
    "Salamanca": 1777,
    "Vicuña": 1892,
    "Angol": 1438,
    "Carahue": 1350,
    "Cholchol": 1346,
    "Collipulli": 1439,
    "Cunco": 1344,
    "Curacautín": 1424,
    "Curarrehue": 1344,
    "Ercilla": 1400,
    "Freire": 1330,
    "Galvarino": 1379,
    "Gorbea": 1311,
    "Lautaro": 1349,
    "Loncoche": 1286,
    "Lonquimay": 1518,
    "Los Sauces": 1423,
    "Lumaco": 1404,
    "Melipeuco": 1400,
    "Nueva Imperial": 1334,
    "Padre Las Casas": 1324,
    "Perquenco": 1365,
    "Pitrufquén": 1322,
    "Pucón": 1286,
    "Purén": 1408,
    "Renaico": 1480,
    "Saavedra": 1414,
    "Temuco": 1325,
    "Teodoro Schmidt": 1332,
    "Toltén": 1361,
    "Traiguén": 1391,
    "Victoria": 1381,
    "Vilcún": 1330,
    "Villarrica": 1309,
    "Ancud": 1165,
    "Calbuco": 1170,
    "Castro": 1106,
    "Chonchi": 1083,
    "Curaco de Vélez": 1123,
    "Dalcahue": 1119,
    "Fresia": 1168,
    "Frutillar": 1141,
    "Llanquihue": 1136,
    "Los Muermos": 1169,
    "Maullín": 1193,
    "Osorno": 1208,
    "Puerto Montt": 1137,
    "Puerto Octay": 1185,
    "Puerto Varas": 1127,
    "Puqueldón": 1118,
    "Purranque": 1182,
    "Puyehue": 1178,
    "Queilén": 1061,
    "Quellón": 1071,
    "Quemchi": 1115,
    "Quinchao": 1138,
    "Río Negro": 1197,
    "San Juan de la Costa": 1230,
    "San Pablo": 1204,
    "Corral": 1259,
    "Futrono": 1219,
    "La Unión": 1218,
    "Lago Ranco": 1196,
    "Lanco": 1259,
    "Los Lagos": 1202,
    "Máfil": 1255,
    "Mariquina": 1251,
    "Paillaco": 1204,
    "Panguipulli": 1262,
    "Río Bueno": 1196,
    "Valdivia": 1280,
    "Bulnes": 1513,
    "Chillán": 1512,
    "Chillán Viejo": 1512,
    "Cobquecura": 1537,
    "Coelemu": 1529,
    "Coihueco": 1534,
    "El Carmen": 1407,
    "Ninhue": 1536,
    "Ñiquén": 1512,
    "Pemuco": 1136,
    "Pinto": 1535,
    "Portezuelo": 1536,
    "Quillón": 1511,
    "Quirihue": 1579,
    "Ránquil": 1521,
    "San Carlos": 1516,
    "San Fabián": 1534,
    "San Ignacio": 1525,
    "San Nicolás": 1512,
    "Treguaco": 1531,
    "Yungay": 1129,
    "Alto Hospicio": 1795,
    "Huara": 1292,
    "Iquique": 1559,
    "Pica": 1945,
    "Pozo Almonte": 1960,
    "Algarrobo": 1486,
    "Cabildo": 1609,
    "Calera": 1583,
    "Calle Larga": 1752,
    "Cartagena": 1484,
    "Casablanca": 1548,
    "Catemu": 1697,
    "Concón": 1501,
    "El Quisco": 1482,
    "El Tabo": 1482,
    "Hijuelas": 1602,
    "La Cruz": 1565,
    "La Ligua": 1292,
    "Limache": 1561,
    "Llaillay": 1695,
    "Los Andes": 1757,
    "Nogales": 1602,
    "Olmué": 1556,
    "Panquehue": 1718,
    "Papudo": 1400,
    "Petorca": 1692,
    "Puchuncaví": 1459,
    "Putaendo": 1774,
    "Quillota": 1558,
    "Quilpué": 1561,
    "Quintero": 1463,
    "Rinconada": 1746,
    "San Antonio": 1495,
    "San Esteban": 1758,
    "San Felipe": 1741,
    "Santa María": 1746,
    "Santo Domingo": 1500,
    "Valparaíso": 1544,
    "Villa Alemana": 1569,
    "Viña Del Mar": 1532,
    "Zapallar": 1378,
    "Alto Biobío": 1463,
    "Antuco": 1511,
    "Arauco": 1454,
    "Cabrero": 1498,
    "Cañete": 1442,
    "Chiguayante": 1464,
    "Concepción": 1477,
    "Contulmo": 1375,
    "Coronel": 1466,
    "Curanilahue": 1432,
    "Florida": 1665,
    "Hualpén": 1490,
    "Hualqui": 1472,
    "Laja": 1484,
    "Lebu": 1468,
    "Los Álamos": 1440,
    "Los Ángeles": 1498,
    "Lota": 1448,
    "Mulchén": 1470,
    "Nacimiento": 1480,
    "Negrete": 1490,
    "Penco": 1470,
    "Quilaco": 1492,
    "Quilleco": 1507,
    "San Pedro de la Paz": 1481,
    "San Rosendo": 1475,
    "Santa Bárbara": 1488,
    "Santa Juana": 1474,
    "Talcahuano": 1516,
    "Tirúa": 1419,
    "Tomé": 1488,
    "Tucapel": 1512,
    "Yumbel": 1501,
    "Chépica": 1548,
    "Chimbarongo": 1494,
    "Codegua": 1624,
    "Coinco": 1584,
    "Coltauco": 1566,
    "Doñihue": 1292,
    "Graneros": 1619,
    "La Estrella": 1604,
    "Las Cabras": 1554,
    "Litueche": 1439,
    "Lolol": 1581,
    "Machalí": 1632,
    "Malloa": 1558,
    "Marchihue": 1591,
    "Nancagua": 1553,
    "Navidad": 1546,
    "Olivar": 1596,
    "Palmilla": 1573,
    "Paredones": 1557,
    "Peralillo": 1571,
    "Peumo": 1562,
    "Pichidegua": 1567,
    "Pichilemu": 1516,
    "Placilla": 1539,
    "Pumanque": 1579,
    "Quinta De Tilcoco": 1578,
    "Rancagua": 1620,
    "Rengo": 1585,
    "Requínoa": 1587,
    "San Fernando": 1546,
    "San Vicente": 1570,
    "Santa Cruz": 1573,
    "Cauquenes": 1589,
    "Chanco": 1550,
    "Colbún": 1480,
    "Constitución": 1546,
    "Curepto": 1400,
    "Curicó": 1407,
    "Empedrado": 1595,
    "Hualañé": 1549,
    "Licantén": 1546,
    "Linares": 1486,
    "Longaví": 1494,
    "Maule": 1522,
    "Molina": 1488,
    "Parral": 1515,
    "Pelarco": 1481,
    "Pelluhue": 1542,
    "Pencahue": 1534,
    "Rauco": 1504,
    "Retiro": 1503,
    "Río Claro": 1482,
    "Romeral": 1504,
    "Sagrada Familia": 1540,
    "San Clemente": 1490,
    "San Javier": 1527,
    "San Rafael": 1501,
    "Talca": 1526,
    "Teno": 1505,
    "Vichuquén": 1536,
    "Villa Alegre": 1522,
    "Yerbas Buenas": 1486,
    "Alhué": 1606,
    "Buin": 1632,
    "Calera De Tango": 1636,
    "Cerrillos": 1653,
    "Cerro Navia": 1659,
    "Colina": 1679,
    "Conchalí": 1292,
    "Curacaví": 1584,
    "El Bosque": 1655,
    "El Monte": 1610,
    "Estación Central": 1658,
    "Huechuraba": 1657,
    "Independencia": 1658,
    "Isla De Maipo": 1619,
    "La Cisterna": 1656,
    "La Florida": 1665,
    "La Granja": 1659,
    "La Pintana": 1660,
    "La Reina": 1659,
    "Lampa": 1661,
    "Las Condes": 1663,
    "Lo Barnechea": 1681,
    "Lo Espejo": 1656,
    "Lo Prado": 1658,
    "Macul": 1661,
    "Maipú": 1642,
    "María Pinto": 1586,
    "Melipilla": 1571,
    "Ñuñoa": 1663,
    "Padre Hurtado": 1622,
    "Paine": 1623,
    "Pedro Aguirre Cerda": 1656,
    "Peñaflor": 1617,
    "Peñalolén": 1654,
    "Pirque": 1671,
    "Providencia": 1659,
    "Pudahuel": 1656,
    "Puente Alto": 1668,
    "Quilicura": 1672,
    "Quinta Normal": 1659,
    "Recoleta": 1662,
    "Renca": 1659,
    "San Bernardo": 1648,
    "San Joaquín": 1656,
    "San José De Maipo": 1627,
    "San Miguel": 1655,
    "San Pedro": 1559,
    "San Ramón": 1658,
    "Santiago": 1657,
    "Talagante": 1619,
    "Tiltil": 1706,
    "Vitacura": 1659
};

const DISTANCIAS = {
  "Combarbalá": 252.76,
    "Illapel": 206.00,
    "Canela": 230.94,
    "Los Vilos": 187.57,
    "Salamanca": 186.15,
    "Valparaiso": 100.24,
    "Viña del Mar": 95.70,
    "Quilpué": 85.36,
    "Villa Alemana": 80.22,
    "Concón": 98.93,
    "Quintero": 109.41,
    "Puchuncaví­": 106.43,
    "Casablanca": 71.87,
    "San Antonio": 90.85,
    "Cartagena": 89.36,
    "El Tabo": 94.73,
    "El Quisco": 97.15,
    "Algarrobo": 94.97,
    "Santo Domingo": 102.69,
    "Los Andes": 67.18,
    "San Felipe": 76.52,
    "Putaendo": 90.23,
    "Santa María": 76.52,
    "Llaillay": 71.73,
    "Catemu": 78.74,
    "Panquehue": 76.00,
    "Quillota": 83.23,
    "La Calera": 88.56,
    "Hijuelas": 84.47,
    "La Cruz": 86.90,
    "Nogales": 93.20,
    "Limache": 75.01,
    "Olmué": 70.00,
    "Petorca": 134.13,
    "La Ligua": 122.32,
    "Cabildo": 118.70,
    "Zapallar": 123.85,
    "Papudo": 127.17,
    "Rancagua": 81.67,
    "Machalí­": 82.60,
    "Graneros": 69.87,
    "Codegua": 66.54,
    "Rengo": 109.47,
    "Requínoa": 95.22,
    "San Vicente": 117.87,
    "Pichidegua": 117.71,
    "Peumo": 116.61,
    "Las Cabras": 92.93,
    "San Fernando": 130.91,
    "Pelarco": 227.94,
    "Pencahue": 241.55,
    "Rí­o Claro": 213.31,
    "San Clemente": 245.24,
    "San Rafael": 267.78,
    "Curicó": 180.04,
    "HualaÃ±Ã©": 201.04,
    "Licanten": 211.18,
    "Molina": 194.76,
    "Rauco": 175.99,
    "Romeral": 174.43,
    "Sagrada Familia": 185.83,
    "Teno": 165.36,
    "Vichuquén": 202.60,
    "Colbún": 259.93,
    "San Javier": 258.94,
    "Villa Alegre": 267.74,
    "Yerbas Buenas": 269.98,
    "La Serena": 396.11,
    "Coquimbo": 298.46,
    "Andacollo": 357.82,
    "La Higuera": 438.46,
    "Paihuano": 378.18,
    "Vicuña": 377.46,
    "Ovalle": 318.63,
    "Monte Patria": 305.58,
    "Punitaqui": 294.38,
    "Rí­o Hurtado": 331.07,
    "Chimbarongo": 145.52,
    "Placilla": 140.00,
    "Nancagua": 143.85,
    "Santa Cruz": 148.86,
    "Lolol": 169.96,
    "Paredones": 177.03,
    "Pichilemu": 163.48,
    "Marchigüe": 139.29,
    "Litueche": 125.07,
    "Navidad": 123.47,
    "La Estrella": 126.05,
    "Palmilla": 144.33,
    "Peralillo": 138.50,
    "Chépica": 154.31,
    "Pumanque": 159.25,
    "Coltauco": 102.26,
    "Doñihue": 92.15,
    "Coinco": 96.42,
    "Olivar": 86.97,
    "Quinta de Tilcoco": 105.74,
    "Talca": 239.54,
    "Constitución": 265.29,
    "Curepto": 222.72,
    "Empedrado": 281.71,
    "Maule": 251.61,
    "Linares": 280.84,
    "Longaví": 296.03,
    "Parral": 318.52,
    "Retiro": 307.80,
    "Cauquenes": 319.36,
    "Pelluhue": 317.40
};

const INVERSORES_STANDAR = [3, 4, 5, 6, 8, 10];

const INVERSOR_PRICES = {
  3: { price: 5280000, panels: 7, quota: 159238 },
  4: { price: 6560000, panels: 10, quota: 197841 },
  5: { price: 7260000, panels: 12, quota: 218952 },
  6: { price: 7960000, panels: 14, quota: 250063 },
  8: { price: 9700000, panels: 18, quota: 292540 },
  10: { price: 11220000, panels: 24, quota: 338381 }
};

export const calculateSolarSystem = (boleta, comuna) => {
  // Parse boleta value
  const montoMensual = parseInt(boleta.replace(/[\$.,]/g, ''));
  const montoAnual = montoMensual * 12;

  // Calculate kWh value based on monthly bill
  let valKwh = 135;
  if (montoMensual < 40000) {
    valKwh = 135;
  } else if (montoMensual < 70000) {
    valKwh = 170;
  } else {
    valKwh = 210;
  }

  // Calculate annual consumption
  const consumoAnual = montoAnual / valKwh;

  // Get kWh for comuna
  const khwComuna = COMUNA_KWH_ANNUAL[comuna] ? COMUNA_KWH_ANNUAL[comuna] * 0.92 : 1600;

  // Calculate inverter size
  const preinversor = (consumoAnual * 1.3) / khwComuna;
  const inversor = Math.round(preinversor / 2) * 2;

  // Find closest standard inverter size
  const inversorBase = INVERSORES_STANDAR.reduce((prev, curr) => 
    Math.abs(curr - inversor) < Math.abs(prev - inversor) ? curr : prev
  );

  // Get system details based on inverter size
  const systemDetails = INVERSOR_PRICES[inversorBase];
  
  // Calculate distance price
  const distancia = DISTANCIAS[comuna] || 1;
  const precioDistancia = distancia * 1850;

  // Calculate energy generation
  const kwp = Math.round(systemDetails.panels * 0.55 * 10) / 10;
  const energiaGen = Math.round(khwComuna * kwp);
  const autoconsumo = Math.round(consumoAnual * 0.33);
  const moneyAutoconsumo = autoconsumo * valKwh;
  const inyeccion = Math.round(energiaGen - autoconsumo);
  const moneyInyeccion = Math.round(inyeccion * 89.42);
  const moneytotal = moneyAutoconsumo + moneyInyeccion;
  let newBoleta = Math.round(montoMensual - (moneytotal / 12));
  
  if (newBoleta < 0) {
    newBoleta = 2000;
  }

  // Calculate final price
  const pfinal = systemDetails.price + precioDistancia;

  return {
    inversorSize: inversorBase,
    numPanels: systemDetails.panels,
    montoMensual,
    kwp,
    energiaGen,
    autoconsumo,
    inyeccion,
    newBoleta,
    finalPrice: pfinal,
    quota: systemDetails.quota
  };
}; 