import React from "react";
import LazyLoad from 'react-lazyload';
import chile from './assets/chile.png';
import logo from './assets/logo.png';
import panelImage2 from './assets/panel32.webp';
import panelImage from './assets/panel_ampliado2.webp';
import scotia from './assets/scotia.png';

import santander from './assets/santander.png';
import { calculateSolarSystem } from './utils/solarCalculations';

const ProposalTemplate = ({ deal }) => {
  // Initialize values from solar calculation
  React.useEffect(() => {
    if (!deal) return;
    
    const boleta = deal?.['625c9af0fff32f70b4cb70fb2df52b2a6668f182'] || '0';
    const comuna = deal?.['d107de31bd7bb1cc8ec7df40418c1c4dfae7f02c'] || 'Peñalolén';
    const solarSystem = calculateSolarSystem(boleta, comuna);
  }, [deal, calculateSolarSystem]);

  

  if (!deal) return null;

  // Get values from deal
  const boleta = deal?.['625c9af0fff32f70b4cb70fb2df52b2a6668f182'] || '0';
  const comuna = deal?.['d107de31bd7bb1cc8ec7df40418c1c4dfae7f02c'] || 'Peñalolén';

  // Calculate solar system details
  const solarSystem = calculateSolarSystem(boleta, comuna);

  // Format numbers with dot separator
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  // Use these values in your template
  const systemSize = solarSystem.kwp;
  const annualGeneration = formatNumber(solarSystem.energiaGen);
  const newBill = formatNumber(solarSystem.newBoleta);
  const finalPrice = formatNumber(solarSystem.finalPrice);
  const quota = formatNumber(solarSystem.quota);
  const autoconsumoValue = formatNumber(solarSystem.autoconsumo);
  const inyeccionValue = formatNumber(solarSystem.inyeccion);
  const numPanelsValue = formatNumber(solarSystem.numPanels);
  const boletaValue = formatNumber(boleta);
  const montoMensual = formatNumber(solarSystem.montoMensual);
  const styles = {
    container: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: '1rem',
    },
    title: {
      fontFamily: "'Gotham', sans-serif",
      fontWeight: 600,
      fontSize: '1.8rem',
    },
    subtitle: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    text: {
      fontFamily: "'Gotham', sans-serif",
      fontWeight: 300,
      fontSize: '1.2rem',
    }
  };

  return (
    <div id="pdfContent" className="container-fluid" style={styles.container}>
      
      {/* Header with background */}
      <div className="row mb-4">
        <div 
          className="col-12" 
          style={{ 
            background: `linear-gradient(135deg, rgba(14, 32, 76, 0.5) 0%, rgba(28, 41, 90, 0.8) 100%), url(${panelImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            padding: '30px',
            overflow: 'hidden'
          }}
        >
          {/* Logo container */}
          <div style={{ 
            position: 'relative', 
            zIndex: 2,
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}>
            <LazyLoad height={120} offset={100}>
              <img 
                src={deal?.logoUrl || logo} 
                alt="Logo" 
                className="img-fluid"
                style={{ 
                  height: "120px",
                  width: "auto",    
                  objectFit: "contain"
                }} 
              />
            </LazyLoad>
          </div>
        </div>
      </div>

      {/* Main Content */}

     {/* Customer Info */}
     <div className="row mb-4">
        <div className="col-md-4">
          <div className="p-3 rounded">
            <p className="fs-4 mb-2 fw-bold">{deal?.org_id?.name || deal?.person_name || 'Nombre del Cliente'}</p>
            <p className="fs-5 mb-1">{deal?.['cf165ed2daeb34c2ce36afc2fde3653590af2767'] || ' '}</p>
            <p className="fs-5 mb-1">{deal?.['d107de31bd7bb1cc8ec7df40418c1c4dfae7f02c'] || ' '}</p>
            <hr className="my-2" />
            <p className="fs-6 mb-1">Fecha: {deal?.date || new Date().toLocaleDateString('es-CL', {
              day: '2-digit',
              month: 'long',
              year: 'numeric'
            })}</p>
            <p className="fs-6 mb-1">Número de Cotización: {deal?.id || '-'}</p>
          </div>
        </div>
        <div className="col-md-1 d-flex justify-content-center">
          <div style={{
            borderLeft: '3px solid #ffd700',
            height: '100%',
          }}></div>
        </div>
        <div className="col-md-7">
          <div className="p-3 rounded">
            <h1 className="display-2 mb-3" style={styles.title}>
              Cotización Proyecto Solar {solarSystem.kwp || '5.2'} kWp
            </h1>
            <p className="lead">
              Tu planta quedará <span className="fw-bold">certificada mediante TE4</span> ante la SEC y <span className="fw-bold">conectada bajo la Ley 21.118</span>, 
              la cual permite la venta de energa hacia la red de la Distribuidora. El sistema contará 
              con un sistema de monitoreo remoto para el correcto control y seguimiento de la generación solar.
            </p>
            <hr className="my-4" />
          </div>
        </div>
      </div>


      {/* Oferta Comercial Section */}
      <div className="row">
        <div className="col-12" style={{
          background: `linear-gradient(rgba(20, 46, 60, 0.75), rgba(20, 46, 60, 0.50)), url(${panelImage2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom',
          padding: '40px 0',
          position: 'relative',
          overflow: 'hidden',
          marginBottom: 0
        }}>
          {/* Title */}
          <div className="text-center mb-4">
            <div style={{
              background: '#efb940',
              padding: '10px 40px',
              borderRadius: '25px',
              display: 'inline-block',
              position: 'relative',
              zIndex: 2,
              margin: '0 auto'
            }}>
              <h2 style={{
                ...styles.title, 
                margin: 0, 
                color: '#ffffff',
                fontWeight: 'bold',
                position: 'relative'
              }}>OFERTA COMERCIAL</h2>
            </div>
          </div>

          {/* Inner Container */}
          <div className="row justify-content-center">
            <div className="col-8">
              <div style={{
                backgroundColor: 'rgba(129, 152, 189, 0.5)',
                borderRadius: '15px',
                padding: '30px',
                backdropFilter: 'blur(5px)',
              }}>
                {/* Boletas Section */}
                <div style={{
                  border: '3.5px solid rgba(255, 255, 255, 0.3)',
                  borderRadius: '10px',
                  padding: '20px',
                  marginBottom: '20px'
                }}>
                  <div className="row">
                    <div className="col-6">
                      <h5 className="text-white fw-bold">Boleta actual</h5>
                      <h5 className="text-white fw-bold">Nueva boleta</h5>
                    </div>
                    <div className="col-6 text-end">
                      <h5 className="text-white fw-bold">${formatNumber(montoMensual)}</h5>
                      <h5 className="text-white fw-bold">${formatNumber(solarSystem.newBoleta)}</h5>
                    </div>
                  </div>
                </div>

                {/* Energy Stats */}
                <div className="text-white">
                  <div className="row">
                    <div className="col-8">
                      <h5 className="fw-bold">ENERGÍA GENERADA</h5>
                      <h5 className="fw-bold">AUTOCONSUMO (33%)</h5>
                      <h5 className="fw-bold">ENERGÍA INYECTADA </h5>
                    </div>
                    <div className="col-4 text-end">
                      <h5 className="fw-bold">{formatNumber(solarSystem.energiaGen)} kWh/Año </h5>
                      <h5 className="fw-bold">{formatNumber(solarSystem.autoconsumo)} kWh/Año </h5>
                      <h5 className="fw-bold">{formatNumber(solarSystem.inyeccion)} kWh/Año </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Características Section */}
      <div className="row">
        <div className="col-12" style={{
          background: '#142E4C',
          padding: '40px 0',
          position: 'relative',
          marginTop: 0
        }}>
          {/* Title */}
          <div className="text-center mb-4">
            <div style={{
              background: '#142E4C',
              padding: '10px 40px',
              borderRadius: '25px',
              border: '2px solid #ffffff',
              display: 'inline-block',
              position: 'relative',
              zIndex: 2,
              margin: '0 auto'
            }}>
              <h2 style={{
                ...styles.title, 
                margin: 0, 
                color: '#ffffff',
                fontWeight: 'bold',
                position: 'relative'
              }}>CARACTERÍSTICAS</h2>
            </div>
          </div>

          {/* Características Content */}
          <div className="row justify-content-center pt-0">
            <div className="col-8">
              <div style={{ backgroundColor: '#142E4C' }} className="pt-4 rounded">
                <div className="row text-center">
                  <div className="col-md-4">
                    <i className="fas fa-microchip fa-3x text-white mb-3"></i>
                    <h4 className="text-white">INVERSOR</h4>
                    <p className="text-white">HUAWEI {solarSystem.inversorSize || ''} kW</p>
                    <p className="text-white">Garantía 10 años</p>
                  </div>
                  <div className="col-md-4">
                    <i className="fas fa-solar-panel fa-3x text-white mb-3"></i>
                    <h4 className="text-white">PANELES SOLARES</h4>
                    <p className="text-white">{formatNumber(solarSystem.numPanels)} paneles Trina Solar</p>
                    
                    <p className="text-white">Garantía 25 años</p>
                  </div>
                  <div className="col-md-4">
                    <i className="fas fa-hammer fa-3x text-white mb-3"></i>
                    <h4 className="text-white"> INSTALACIÓN</h4>
                    <p className="text-white"> Garantía 1 año</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

      {/* Price Section */}
      <div className="row mb-4">
        <div className="col-12 p-0">
          <div className=" px-4 py-3  shadow text-center w-100" style={{ backgroundColor: '#efb940' }}>
            <h3 style={{...styles.subtitle, color: '#ffffff', fontSize: '1.3rem', fontWeight: 'bold'}}>PRECIO FINAL</h3>
            <div className="bg-white p-3 shadow-sm my-2 mx-auto" style={{
              maxWidth: '80%',
              borderRadius: '40px'
            }}>
              <h2 className="display-1" style={{
                ...styles.title, 
                color: '#0e204c',
                fontSize: '2.5rem',
                fontWeight: 'bold',
                letterSpacing: '-0.02em'
              }}>
                {'$'} {finalPrice} IVA incluido
              </h2>
            </div>
          </div>
        </div>
      </div>

      {/* Bank Financing Section */}
      <div className="row justify-content-center mb-4">
        <div className="col-12 text-center">
          
          <div className="d-flex justify-content-center gap-3 my-3">
            {/* Santander Container */}
            <div className="text-center">
              <div style={{
                backgroundColor: '#EC0000',
                color: 'white',
                padding: '20px 40px',
                borderRadius: '8px',
                width: '300px',
                height: '80px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                marginBottom: '10px'
              }}>
                <img src={santander} alt="Santander" style={{ height: '40px', marginRight: '10px' }} />
              
              </div>
              <p style={{ color: '#0e204c', fontSize: '0.9rem', margin: 0 }}>
              Hasta <span style={{fontWeight: 'bold'}}>36 cuotas</span>
              </p>
            </div>

            {/* Banco de Chile Container */}
            <div className="text-center">
              <div style={{
                backgroundColor: '#00205B',
                color: 'white',
                padding: '20px 40px',
                borderRadius: '8px',
                width: '300px',
                height: '80px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                marginBottom: '10px'
              }}>
                <img src={chile} alt="Banco de Chile" style={{ height: '40px', marginRight: '10px' }} />
               
              </div>
              <p style={{ color: '#0e204c', fontSize: '0.9rem', margin: 0 }}>
              Hasta <span style={{fontWeight: 'bold'}}>24 cuotas</span> sin interés
              </p>
            </div>

            {/* Scotiabank Container */}
            <div className="text-center">
              <div style={{
                backgroundColor: '#EC0000',
                color: 'white',
                padding: '20px 40px',
                borderRadius: '8px',
                width: '300px',
                height: '80px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                marginBottom: '10px'
              }}>
                <img src={scotia} alt="Scotiabank" style={{ height: '40px', marginRight: '3px' }} />
                
              </div>
              
              <p style={{ color: '#0e204c', fontSize: '0.9rem', margin: 0}}>
              Hasta <span style={{fontWeight: 'bold'}}>24 cuotas</span> sin interés
              </p>
            </div>
          </div>
          <p className="mb-0" style={{ fontSize: '0.9rem' }}>
            *El precio está sujeto a confirmación durante la visita técnica. La oferta es válida por 30 días e incluye un punto de conexión en norma. <br></br> Además, el sistema de monitoreo requiere de Wifi proporcionado por el cliente.
          </p>
        </div>
      </div>

      {/* Footer */}
      <footer className="row">
        <div 
          className="col-12" 
          style={{ 
            background: '#142E4C',
            padding: '20px',
            position: 'relative',
            marginTop: '0',
            pageBreakInside: 'avoid',
            breakInside: 'avoid',
            width: '100%'
          }}
        >
          <div className="row align-items-center">
            <div className="col-4">
              <img 
                src={deal?.logoUrl || logo} 
                alt="Logo" 
                className="img-fluid"
                style={{ 
                  height: "60px",
                  width: "auto",    
                  objectFit: "contain",
                  filter: "brightness(0) invert(1)",
                  marginLeft: "0"
                }} 
              />
            </div>
            <div className="col-8 text-end">
              <p className="text-white mb-0" style={{ fontSize: '0.9rem' }}>
                {'Nikola '} | {'Correo: '}{deal?.owner?.email || 'aura@nikola.cl'} | { 'Teléfono: +569 83790066'}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ProposalTemplate;
